<template>
  <div>
    <b-card class="mb-3">
      <div class="d-flex justify-content-between mb-4">
        <strong class="d-block text-left mb-1">
          Analise resumida
        </strong>
      </div>

      <div class="d-flex">
        <div class="d-flex align-items-center justify-content-between gap-2">
          <div class="d-flex flex-column justify-content-center align-items-center h-100 px-4"
            :class="`bg-${getRisc().variant}`">
            <strong class="d-block text-white">Resultado</strong>
            <h2 class="mb-0 line-height-1 text-white text-center" v-if="validation && validation.result">
              <span class="d-block mx-4 my-2" v-if="score">
                {{ score.toFixed(2) }}
              </span>
            </h2>
            <span class="d-block text-white">{{ getRisc().text }}</span>
          </div>
        </div>
        <b-table :items="indicators" :fields="fields" small class="m-0" :tbody-tr-class="rowClass">
          <template #cell(indicator)="data">
            <span class="text-left mx-3 d-block" :class="[isMatch(data.item) && 'text-white']">
              Pontuação entre <strong>{{ data.item.min.toFixed(1) }}</strong> e
              <strong>{{ data.item.max.toFixed(1) }}</strong>
            </span>
          </template>
          <template #cell(risc)="data">
            <span :class="[isMatch(data.item) && 'text-white font-weight-bold']">
              {{ getRisc(data.item.risc).text }}
            </span>
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {
      score: 0,
      risc: 1,
      loading: false,
      indicators: [
        {
          indicator: "0.0 - 2.0",
          min: 0,
          max: 2,
          risc: 1,
        },
        {
          indicator: "2.1 - 4.0",
          min: 2.1,
          max: 4,
          risc: 2,
        },
        {
          indicator: "4.1 - 6.0",
          min: 4.1,
          max: 6,
          risc: 3,
        },
        {
          indicator: "6.1 - 8.0",
          min: 6.1,
          max: 8,
          risc: 4,
        },
        {
          indicator: "8.1 - 10.0",
          min: 8.1,
          max: 10,
          risc: 5,
        },
      ],
      fields: [
        {
          key: "indicator",
          label: "Indicador da pontuação",
          sortable: false,
          thClass: "px-3",
        },
        {
          key: "risc",
          label: "Nível de risco",
          sortable: false,
        },
      ],
    };
  },

  props: {
    validation: Object,
  },

  methods: {
    ...mapActions(["select_provider_winner"]),

    isMatch(item) {
      const score = this.score;
      return score >= item.min && score <= item.max;
    },

    getRisc(localRisc) {
      const risc = localRisc ? localRisc : this.risc;

      switch (true) {
        case risc <= 1:
          return {
            text: "Não recomendado",
            variant: "danger",
          };
        case risc > 1 && risc <= 2:
          return {
            text: "Risco alto",
            variant: "danger",
          };
        case risc > 2 && risc <= 3:
          return {
            text: "Risco médio",
            variant: "warning",
          };
        case risc > 3 && risc <= 4:
          return {
            text: "Risco baixo",
            variant: "primary",
          };
        case risc > 4:
          return {
            text: "Risco irrelevante",
            variant: "success",
          };
      }
    },

    rowClass(item) {
      const score = this.score;
      if (score >= item.min && score <= item.max)
        return `bg-${this.getRisc().variant}  text-white`;
    },

    handleSelectProviderToService() {
      this.$bvModal.show("select-provider-service-validation");
    },

    handleCancel() {
      this.$bvModal.hide("select-provider-service-validation");
    },
  },

  watch: {
    validation: {
      handler({ result }) {
        if (result) {
          this.score = result.score;

          switch (true) {
            case this.score <= 2:
              this.risc = 1;
              break;
            case this.score > 2 && this.score <= 4:
              this.risc = 2;
              break;
            case this.score > 4 && this.score <= 6:
              this.risc = 3;
              break;
            case this.score > 6 && this.score <= 8:
              this.risc = 4;
              break;
            case this.score > 8:
              this.risc = 5;
              break;
          }
        }
      },
      immediate: true,
    },
  },
};
</script>
